import React from 'react'

import { Spin } from 'antd'

import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { AuthProvider } from 'oidc-react'

import Logout from './views/pages/logout'

import './scss/style.scss'
import 'react-toastify/dist/ReactToastify.css'
import {WebStorageStateStore} from "oidc-client";

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const loading = (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      state: process.env.REACT_APP_BASE_KEY_STATE,
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Spin size={'large'} tip="Loading..." />
  </div>
)

const App = () => {
  const dispatch = useDispatch()

  const oidcConfig = {
    onSignIn: async (user) => {
      dispatch({ type: 'LOGIN_TRUE' })
      localStorage.setItem('user', JSON.stringify(user))
    },
    authority: process.env.REACT_APP_BASE_URL_OIDC,
    clientId: 'crew_idp_client',
    redirectUri: `${window.location.origin}/cb`,
    scope: 'openid profile roles offline_access IdentityServerApi',
    response_type: 'code',
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  }
  // const manager = new UserManager(oidcConfig)

  // manager.signinRedirectCallback((user)=>{
  //   console.log(user)
  // })
  // useEffect(() => {
  //   window.localStorage.setItem('REQUESTING_SHARED_CREDENTIALS', Date.now().toString())
  //   window.localStorage.removeItem('REQUESTING_SHARED_CREDENTIALS')
  // }, [])

  // window.addEventListener('storage', (event) => {
  //   const credentials = getSessionStorage()
  //   if (event.key === 'REQUESTING_SHARED_CREDENTIALS' && credentials) {
  //     window.localStorage.setItem('CREDENTIALS_SHARING', credentials)
  //     window.localStorage.removeItem('CREDENTIALS_SHARING')
  //   }
  //   if (event.key === 'CREDENTIALS_SHARING' && !credentials) {
  //     window.sessionStorage.setItem(`oidc.user:${process.env.REACT_APP_BASE_URL_OIDC}:admin_idp_client`, event.newValue)
  //   }
  //   if (event.key === 'CREDENTIALS_FLUSH' && credentials) {
  //     window.sessionStorage.removeItem(`oidc.user:${process.env.REACT_APP_BASE_URL_OIDC}:admin_idp_client`)
  //   }
  // })
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <AuthProvider {...oidcConfig}>
          <Switch>
            <Route exact path="/logout" name="Logout" component={Logout} />
            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </AuthProvider>
      </React.Suspense>
    </BrowserRouter>
  )
}

export default App
