import axios from 'axios'
import { getSessionStorage } from 'src/utils/helpers/getDataSessionStorage'
import formurlencoded from 'form-urlencoded'

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
})

// set access token
instance.interceptors.request.use((config) => {
  config.headers.Authorization = 'Bearer ' + getSessionStorage('access_token')
  return config
})

//refresh token

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (err) => {
    return new Promise((resolve, reject) => {
      const originalReq = err.config

      if (
        err.response &&
        err.response.status === 401 &&
        err.config &&
        !err.config.__isRetryRequest
      ) {
        originalReq._retry = true
        let res = instance
          .post(
            `${process.env.REACT_APP_BASE_URL_OIDC}/connect/token`,
            formurlencoded({
              client_id: 'crew_idp_client',
              grant_type: 'refresh_token',
              refresh_token: getSessionStorage('refresh_token'),
            }),
            {
              headers: {
                'content-type': 'application/x-www-form-urlencoded',
              },
            },
          )
          .then((res) => {
            console.log('refresh', res.data)
            const userData = getSessionStorage()
            const { access_token, refresh_token, id_token } = res.data
            userData.access_token = access_token
            userData.refresh_token = refresh_token
            userData.id_token = id_token
            sessionStorage.setItem(
              `oidc.user:${process.env.REACT_APP_BASE_URL_OIDC}:crew_idp_client`,
              JSON.stringify(userData),
            )
            return instance(originalReq)
          })
          .catch((err) => console.log(err))

        resolve(res)
      }

      return reject(err)
    })
  },
)

export default instance
